import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Carousel, {
  slidesToShowPlugin,
  autoplayPlugin,
} from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";

export const SliderService = () => {
  const [t] = useTranslation("global");

  const [itemProducts, setItemProducts] = useState([]);

  useEffect(() => {
    const detailsProducts = [
      {
        id: 1,
        nameProduct: "Red Voucher",
				subName:t("products.subname_rvo"),
        status: false,
        abrev: "rvo",
        descriptionProduct: t("products.descrip_rvo"),
        urlSite: "https://redvoucher.com/",
        logoProduct: "assets/imgs/logos/logo_rv.png",
      },
      {
        id: 2,
        nameProduct: "Kuimby",
				subName:t("products.subname_kuimby"),
        status: true,
        abrev: "kuimby",
        descriptionProduct: t("products.descrip_kuimby"),
        urlSite: "https://kuimby.com/",
        logoProduct: "assets/imgs/logos/logo_kuimby.png",
      },
      {
        id: 3,
        nameProduct: "Red Pay",
				subName:t("products.subname_rpay"),
        status: false,
        descriptionProduct: t("products.descrip_rpay"),
        abrev: "rpay",
        urlSite: "https://www.redpay.mx/",
        logoProduct: "assets/imgs/logos/logo_rpay.png",
      },
      {
        id: 4,
        nameProduct: "Red Access",
				subName:t("products.subname_ra"),
        status: true,
        descriptionProduct: t("products.descrip_ra"),
        abrev: "ra",
        urlSite: "https://sistema.redaccess.com/",
        logoProduct: "assets/imgs/logos/logo_ra.png",
      },
      // {
      //   id: 5,
      //   nameProduct: "Red Access Online",
			// 	subName:t("products.subname_rao"),
      //   status: false,
      //   abrev: "rao",
      //   descriptionProduct: t("products.descrip_rao"),
      //   urlSite: "https://www.redaccessonline.com/",
      //   logoProduct: "assets/imgs/logos/logo_rao.png",
      // },
      // {
      //   id: 6,
			// 	subName:t("products.subname_rpmkp"),
      //   status: false,
      //   nameProduct: "Red Pay Marketplace",
      //   abrev: "rpmkp",
      //   descriptionProduct: t("products.descrip_rpmkt"),
      //   urlSite: "https://redpay.mx/marketplace/",
      //   logoProduct: "assets/imgs/logos/logo_rpmkp.png",
      // },
      {
        id: 7,
        nameProduct: "Red Bear",
				subName:t("products.subname_rbear"),
        status: true,
        abrev: "rbear",
        descriptionProduct: t("products.descrip_rbear"),
        urlSite: "https://www.redbear.mx/",
        logoProduct: "assets/imgs/logos/logo_rbear.png",
      },
    ];
    setItemProducts(detailsProducts);
  }, [t]);

  return (
    <section className="wrapper_slider_service">
      <div className="container">
        <div className="row align-items-center box_ttls_sec">
          <div className="col-md-6">
            <h4 className="ttls ttls_sections first mb-0">
              <span className="bg_span yellow text-light">
                {t("home.solutions_title_small")}
              </span>
            </h4>
            <h3 className="ttls ttls_sections mmenos">
              {t("home.solutions_title_1")}{" "}
              <span className="c_green">{t("home.solutions_title_2")}</span>
            </h3>
          </div>
          <div className="col-md-6">
            <p className="paragraphs box_p ">{t("products.info_product")}</p>
          </div>
        </div>
      </div>
      <div className="container_slider">
        <Carousel
          plugins={[
            "arrows",
            "infinite",
            {
              resolve: slidesToShowPlugin,
              options: {
                numberOfSlides: 3,
              },
            },
            {
              resolve: autoplayPlugin,
              options: {
                interval: 2500,
              },
            },
          ]}
          breakpoints={{
            1024: {
              plugins: [
                "arrows",
                "infinite",
                {
                  resolve: slidesToShowPlugin,
                  options: {
                    numberOfSlides: 2,
                  },
                },
                {
                  resolve: autoplayPlugin,
                  options: {
                    interval: 2500,
                  },
                },
              ],
            },
            680: {
              plugins: [
                "arrows",
                "infinite",
                {
                  resolve: slidesToShowPlugin,
                  options: {
                    numberOfSlides: 1,
                  },
                },
                {
                  resolve: autoplayPlugin,
                  options: {
                    interval: 2500,
                  },
                },
              ],
            },
          }}
        >
          {itemProducts.map((itemProduct) => (
            <div
              className={`box_item_solutions ${itemProduct.status ? "mt" : ""}`}
              key={itemProduct.id}
            >
              <h1 className="number_product">0{itemProduct.id}</h1>
              <div className="item_solutions">
                <h1 className="ttls ">{itemProduct.nameProduct}</h1>
                <div className='subname'>{itemProduct.subName}</div>
                <p className="p_paragraph">{itemProduct.descriptionProduct}</p>
                <div>
                  <a
                    className="btns btn_compress "
                    target="_blank"
                    rel="noopener noreferrer"
                    href={itemProduct.urlSite}
                  >
                    <span>{t("products.more2")}</span>
                  </a>
                </div>
              </div>
              <div className={`box_picture ${itemProduct.abrev}`}>
                <img
                  className="logo_solution"
                  src={itemProduct.logoProduct}
                  alt="Somos Red Companies"
                />
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </section>
  );
};
