import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DataBusiness } from "../components/common/DataBusiness";
import { Customers } from "../components/common/Customers";
import { NavLink } from "reactstrap";
import { Link } from "react-router-dom";

export const Productos = () => {
  const [t] = useTranslation("global");

  const [itemProducts, setItemProducts] = useState([]);
  useEffect(() => {
    const detailsProducts = [
      {
        id: 1,
        status: false,
        nameProduct: "Red Voucher",
        subName: t("products.subname_rvo"),
        abrev: "rvo",
        descriptionProduct: t("products.descrip_rvo"),
        urlSite: "https://redvoucher.com/",
        logoProduct: "assets/imgs/ico_rvo.svg",
      },
      {
        id: 2,
        status: false,
        nameProduct: "Red Access",
        subName: t("products.subname_ra"),
        abrev: "raccess",
        descriptionProduct: t("products.descrip_ra"),
        urlSite: "https://sistema.redaccess.com/",
        logoProduct: "assets/imgs/ico_access.svg",
      },
      // {
      // 	id: 3,
      // 	status: false,
      // 	nameProduct: "Red Access Online",
      // 	subName:t("products.subname_rao"),
      // 	abrev: "raccessonline",
      // 	descriptionProduct: t("products.descrip_rao"),
      // 	urlSite: "https://www.redaccessonline.com/",
      // 	logoProduct: "assets/imgs/ico_access.png",
      // },
      {
        id: 4,
        status: true,
        nameProduct: "Kuimby",
        subName: t("products.subname_kuimby"),
        abrev: "redkuimby",
        descriptionProduct: t("products.descrip_kuimby"),
        urlSite: "https://kuimby.com/",
        logoProduct: "assets/imgs/ico_kuimby.jpg",
      },
      {
        id: 5,
        status: false,
        nameProduct: "Red Pay ",
        subName: t("products.subname_rpay"),
        abrev: "rpay",
        descriptionProduct: t("products.descrip_rpay"),
        urlSite: "https://www.redpay.mx/",
        logoProduct: "assets/imgs/ico_redpay.png",
      },
      // {
      // 	id: 6,
      // 	status: false,
      // 	nameProduct: "Red Pay Marketplace",
      // 	subName:t("products.subname_rpmkp"),
      // 	abrev: "rpmkp",
      // 	descriptionProduct: t("products.descrip_rpmkp"),
      // 	urlSite: "https://redpay.mx/marketplace/",
      // 	logoProduct: "assets/imgs/ico_rpmkp.png",
      // },
      {
        id: 7,
        status: false,
        nameProduct: "Red Bear",
        subName: t("products.subname_rbear"),
        abrev: "rbear",
        descriptionProduct: t("products.descrip_rbear"),
        urlSite: "https://www.redbear.mx/",
        logoProduct: "assets/imgs/ico_bear.svg",
      },
    ];
    setItemProducts(detailsProducts);
  }, [t]);

  const updateStatus = (idProduct) => {
    const changeStatus = itemProducts.map((myProduct) => {
      if (idProduct === myProduct.id) {
        myProduct.status = true;
      } else {
        myProduct.status = false;
      }
      return myProduct;
    });
    setItemProducts(changeStatus);
  };

  return (
    <div>
      <section className="wrapper_products" id="home">
        <div className="flex_container vh">
          <div className="container">
            <div className="row">
              <div className="col-md-6 contact_info">
                <h6 className="ttls_small">
                  {t("products.ttl_small_product")}
                </h6>
                <div className="">
                  <h2 className="ttls two m-0">
                    {" "}
                    {t("products.ttl_product_one")}{" "}
                    <span>{t("products.ttl_product_two")}</span>
                  </h2>
                  <h1 className="paragraphs">{t("products.info_product")}</h1>
                  <p className="mt-5">
                    <NavLink
                      className="btns btn_compress"
                      tag={Link}
                      to="/contacto"
                    >
                      <span>{t("products.link_product")}</span>
                    </NavLink>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper_accordion">
        <div className="container">
          <div className="options">
            {itemProducts.map((itemProduct) => (
              <div
                className={`option ${itemProduct.abrev} ${
                  itemProduct.status ? "active" : ""
                }`}
                key={itemProduct.id}
                onMouseEnter={() => updateStatus(itemProduct.id)}
              >
                <div className="item_name_prod ">
                  <span className="ttls">{itemProduct.nameProduct}</span>
                </div>

                <div className="label">
                  <div className="icon">
                    <img
                      src={itemProduct.logoProduct}
                      alt={
                        itemProduct.nameProduct +
                        ", " +
                        itemProduct.descriptionProduct
                      }
                    />
                  </div>
                  <div className="info">
                    <div className="main ttls fw4">
                      {itemProduct.nameProduct}
                    </div>
                    <div className="subname">{itemProduct.subName}</div>

                    <div className="sub">{itemProduct.descriptionProduct}</div>
                  </div>
                  <div className="text-center">
                    <a
                      className="btn_more_prod trans"
                      href={itemProduct.urlSite}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("products.more")}
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <DataBusiness />
      <Customers />
    </div>
  );
};
