import React from "react";
import { useTranslation } from "react-i18next";
import { DataBusiness } from "../components/common/DataBusiness";
import { NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import { Customers } from "../components/common/Customers";
import { Countries } from "./Countries";
import { SliderService } from "./common/SliderService";
// video  3 4
import video from "./../assets/videos/video-3.mp4";
import videoWebm from "./../assets/videos/video-3.webm";
import bg_poster from "./../assets/imgs/bg_home.png";
import scr_down from "./../assets/imgs/scr.gif"

export const Home = () => {
  const [t] = useTranslation("global");

  return (
    <div>
      <section className="wrapper_home" id="home">
        <div className="bg_video">
          <video autoPlay loop muted poster={bg_poster}>
            <source src={video} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
            <source src={videoWebm} type='video/webm; codecs="vp8, vorbis"' />
          </video>
        </div>
        <div className="container">
          <div className="row align-items-center vh">
            <div className="col-lg-7">
              <h6 className="ttls_small">{t("home.title_welcome")}</h6>
              <h1 className="message ">{t("home.title_message")}</h1>
              <p className="paragraphs">{t("home.title_message_descrip")}</p>
            </div>
          </div>
        </div>
        <div className="box_scr_down">
          <div className='label_sc'>Scroll me</div>
          <img src={scr_down} alt="Conoce de marketing y tecnología, expertos en provocar ventas y la permanencia de sus clientes a través de soluciones digitales, incentivos y premios de las marcas más reconocidas." />
          {/* <a href="#offer">{t("home.more")}</a> */}
        </div>
      </section>

      <section className="wrapper_offer" id="offer">
        <div className="box_container">
          <div className="container">
            <div className="row ">
              <div className="col-md-6">
                <h6 className="ttls_small offer">{t("offer.we_offer")}</h6>
                <h3 className="ttls ttls_sections mt-2">
                  {t("offer.label_innovation")}{" "}
                  <span className="bg_span lemon text-light">
                    {t("offer.label_technoloy")}
                  </span>
                </h3>
                <p className="paragraphs mt-4 pb-4">
                  {t("offer.offer_descriptions")}
                </p>
                <div className=" mt-5">
                  <NavLink
                    className="btns btn_compress"
                    tag={Link}
                    to="/productos"
                  >
                    <span>{t("offer.get_products")}</span>
                  </NavLink>
                </div>
              </div>
              <div className="col-md-6">
                <div className="box_offer">
                  <h2 className="fsize dos">{t("offer.service_one")}</h2>
                  <h2 className="fsize uno">{t("offer.service_two")}</h2>
                  <h2 className="fsize dos">{t("offer.service_four")}</h2>
                  <h2 className="fsize uno">{t("offer.service_three")}</h2>
                  <h2 className="fsize dos ">{t("offer.service_five")}</h2>
                  <h2 className="fsize dos">{t("offer.service_six")}</h2>
                  <h2 className="fsize uno">{t("offer.service_seven")}</h2>
                  <h2 className="fsize dos">{t("offer.service_eight")}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <SliderService />
      <Countries />
      <DataBusiness />
      <Customers />
    </div>
  );
};
