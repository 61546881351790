import React from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect
} from 'react-router-dom'
import {
    Layout,
    LayoutLess
} from './components/Layout'
import { Home } from './components/Home';
import { Nosotros } from './components/Nosotros';
import { Productos } from './components/Productos';
import { Contacto } from './components/Contacto';
import { TerminosCondiciones } from './components/TerminosCondiciones'
import { AvisoPrivacidad } from './components/AvisoPrivacidad'
import { CatalogoPromos } from './components/CatalogoPromos'
import { Gratitude } from './components/Gratitude'

export function App() {
    return (
        <Router>
            <Switch>
                <Route exact path='/' render={x => (<Layout children={<Home {...x} />} />)} />
                <Route exact path='/catalogo' render={x => (<LayoutLess children={<CatalogoPromos {...x} />} />)} />
                <Route exact path='/nosotros' render={x => (<Layout children={<Nosotros {...x} />} />)} />
                <Route exact path='/productos' render={x => (<Layout children={<Productos {...x} />} />)} />
                <Route exact path='/contacto' render={x => (<Layout children={<Contacto {...x} />} />)} />
                <Route exact path='/terminosycondiciones' render={x => (<Layout children={<TerminosCondiciones {...x} />} />)} />
                <Route exact path='/avisosyprivacidad' render={x => (<Layout children={<AvisoPrivacidad {...x} />} />)} />
                <Route exact path='/gratitude' render={x => (<LayoutLess children={<Gratitude {...x} />} />)} />

                <Redirect from='/catalogo2020' to='/catalogo' />
            </Switch>
        </Router>
    )
}
