import React from "react";
import { useTranslation } from "react-i18next";

export const DataBusiness = () => {
  const [t] = useTranslation("global");

  const fechaAniversario = 2015;
  // const fecha1 = new Date("12/31/2020").getTime();
  const fecha1 = new Date("12/31/2020 00:00:00").getTime();
  const fecha2 = new Date().getTime();

  // const milisegHora = 3600000 //miliseg en 1 hora
  const milisegDia = 86400000; //milisegundos en 1 dia
  const totalmiliseg = Math.abs(fecha1 - fecha2);
  const totalDias = Math.round(totalmiliseg / milisegDia);
  // const totalHoras = Math.round(totalmiliseg / milisegHora);
  
  // console.log(totalmiliseg);
  // console.log(totalDias);
  // console.log(fecha1);
  // console.log(fecha2);

  const customerInitial = 600;
  const customerTotal = customerInitial + totalDias;

  const totalAnios = new Date().getFullYear() - fechaAniversario;

  return (
    <section className="wrapper_data">
      <div className="container">
        <div className="box_tittle">
          <h6 className="ttls_small text-center mb-2">
            {t("dataBusiness.label_title_one")}
          </h6>
          <h1 className="text-center ttls">
            <span>{t("dataBusiness.label_title_two")}</span>{" "}
            {t("dataBusiness.label_title_three")}
          </h1>
        </div>
        <div className="row">
          <div className="col-md-3 col-sm-6">
            <div className="box_item_data">
              <h1 className="counter">{totalAnios}</h1>
              <h6 className="text-uppercase">{t("dataBusiness.experience")}</h6>
              <p className="paragraphs ">
                {t("dataBusiness.label_experience")}
              </p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="box_item_data">
              <h1 className="counter">{customerTotal}</h1>
              <h6 className="text-uppercase">{t("dataBusiness.customer")}</h6>
              <p className="paragraphs ">{t("dataBusiness.label_customer")}</p>
              {/* <p className="paragraphs">{totalHoras}</p> */}
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="box_item_data">
              <h1 className="counter">8</h1>
              <h6 className="text-uppercase">{t("dataBusiness.countries")}</h6>
              <p className="paragraphs ">{t("dataBusiness.label_countries")}</p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="box_item_data">
              <h1 className="counter">+900</h1>
              <h6 className="text-uppercase">{t("dataBusiness.millions")}</h6>
              <p className="paragraphs ">{t("dataBusiness.label_millions")}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
