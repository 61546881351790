import React from 'react';
import { useTranslation } from "react-i18next"

export const TerminosCondiciones = () => {
  const [t] = useTranslation("global");

  return (
    <section className="wrapper_legals">
      <div className="box_tittle">
        <div className="container">
          <h1 className="ttls mb-2"><span>{t("others.terms_conditions_one")} </span> {t("others.terms_conditions_two")}</h1>
          <h6 className="ttls_small">{t("others.website")}</h6>
        </div>
      </div>
      <div className="content_legals">
        <div className="container">
          <p>{t("terms_conditions.legal_p1")}</p>
          <p>{t("terms_conditions.legal_p2")}</p>
          <p>{t("terms_conditions.legal_p3")}</p>
          <p>{t("terms_conditions.legal_p4")}</p>
          <p>{t("terms_conditions.legal_p5")}</p>
          <p>{t("terms_conditions.legal_p6")}</p>
          <p>{t("terms_conditions.legal_p7")}</p>
          <p>{t("terms_conditions.legal_p8")}</p>
          <p>{t("terms_conditions.legal_p9")}</p>
          <p>{t("terms_conditions.legal_p10")}</p>
          <p>{t("terms_conditions.legal_p11")}</p>
          <p>{t("terms_conditions.legal_p12")}</p>
          <p>{t("terms_conditions.legal_p13")}</p>
        </div>
      </div>
    </section>
  );
}
