import React from 'react'

import bg from '../assets/imgs/bg_catalog.png';

export const Gratitude = () => {
    // setTimeout(() => {
    //   window.location.href = "/"
    // }, 4000);
  return (
    <section className="wrapper_catalogos">
      <article className="drawer_home">
        <div className="container-gratitude">
          <div className="container-gratitude-img" style={{ backgroundImage: `url(${bg})` }}>
          </div>
          <div className="container-gratitude-info">
            <h1 className="container-gratitude-info-title">Gracias</h1>
            <h2 className="container-gratitude-info-subtitle">Contáctanos</h2>
            <div className="container-gratitude-info-email">
              <a href="mailto:hola@redcompanies.com.mx" target="_blank">hola@redcompanies.com.mx</a>
            </div>
            <div className="container-gratitude-info-address">
              <p>Calle Texas #39, Col. Nápoles,</p>
              <p>Del. Benito Juárez, CDMX, México</p>
              <p>C.P. 03810</p>
            </div>
            <div className="container-gratitude-info-site">
              <a href="https://redvoucheronline.com.mx" target="_blank">www.redvoucheronline.com.mx</a>
              <a href="https://somosredcompanies.com/">www.somosredcompanies.mx</a>
            </div>
          </div>
        </div>
      </article>
    </section>
  )
} 