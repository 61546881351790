import React, { useState } from 'react';
import { useTranslation } from "react-i18next"

const countriesMaps = [
  {
    id: 1,
    nameCountry: "México",
    urlMapWorld: "assets/imgs//maps/maps_mx.svg",
    urlMapCountry: "assets/imgs/countries/map-mexico.svg",
    isActive: true,
  },
  {
    id: 2,
    nameCountry: "Guatemala",
    urlMapWorld: "assets/imgs//maps/maps_gt.svg",
    urlMapCountry: "assets/imgs/countries/map-guatemala.svg",
    isActive: false,
  },
  {
    id: 3,
    nameCountry: "Honduras",
    urlMapWorld: "assets/imgs/maps/maps_hd.svg",
    urlMapCountry: "assets/imgs/countries/map-honduras.svg",
    isActive: false,
  },
  {
    id: 4,
    nameCountry: "El salvador",
    urlMapWorld: "assets/imgs/maps/maps_sal.svg",
    urlMapCountry: "assets/imgs/countries/map-elSalvador.svg",
    isActive: false,
  },
  {
    id: 5,
    nameCountry: "Costa rica",
    urlMapWorld: "assets/imgs/maps/maps_cr.svg",
    urlMapCountry: "assets/imgs/countries/map-costarica.svg",
    isActive: false,
  },
  {
    id: 6,
    nameCountry: "Colombia",
    urlMapWorld: "assets/imgs/maps/maps_col.svg",
    urlMapCountry: "assets/imgs/countries/map-colombia.svg",
    isActive: false,
  },
  {
    id: 7,
    nameCountry: "Puerto rico",
    urlMapWorld: "assets/imgs/maps/maps_pr.svg",
    urlMapCountry: "assets/imgs/countries/map-puertorico.svg",
    isActive: false,
  },
  {
    id: 8,
    nameCountry: "España",
    urlMapWorld: "assets/imgs/maps/maps_es.svg",
    urlMapCountry: "assets/imgs/countries/map-espania.svg",
    isActive: false,
  },
]

export function Countries() {
  const [t] = useTranslation("global");

  const [myCountries, setCountries] = useState(countriesMaps)

  const updateActive = (idCountry) => {
    const changeActive = myCountries.map((country) => {
      if (idCountry === country.id) {
        country.isActive = true;
      } else {
        country.isActive = false;
      }
      return country;
    });
    setCountries(changeActive)
  }

  return (
    <section className="wrapper_presence">
      <div className="container">
        <div className="content_presence">
          <div className="box_ttls_prensence">
            <h2 className="ttls display_1">{t("countries.label_title_one")} <span>{t("countries.label_title_two")}</span></h2>
            <h2 className="ttls display_1">{t("countries.label_title_three")}</h2>
          </div>
          <div className="box_info_prensence align-items-end">
            <div className="box_maps_descrip">
              {myCountries.map(itemCountry => (
                <div className="maps_ttls" key={itemCountry.id}>
                  <span
                    className={`maps_item_ttl ${itemCountry.isActive ? 'active_ttl' : ''}`}
                  >
                    <span>{itemCountry.nameCountry}</span>
                  </span>
                </div>
              ))}
              {myCountries.map(itemCountry => (
                <div className="maps_img" key={itemCountry.id}>
                  <img
                    className={`mapa ${itemCountry.isActive ? 'active_mapa' : ''}`}
                    src={itemCountry.urlMapCountry}
                    alt=""
                  />
                  <div className={`info_state_mapa ${itemCountry.nameCountry === "México" && itemCountry.isActive ? 'show' : ''}`}>
                    <p>{t('countries.label_presence')} </p>
                    <p><i>Tijuana, Monterrey, Guadalajara, CDMX, Querétaro y Mérida</i></p>
                  </div>
                </div>
              ))}
            </div>
            <div className="box_countries">
              {myCountries.map(itemCountry => (
                <h3
                  className="item_country"
                  onMouseEnter={() => updateActive(itemCountry.id)}
                  key={itemCountry.id}
                >
                  <span
                    className={`span_item_country ${itemCountry.isActive ? 'active_ttl' : ''}`}
                  >
                    {itemCountry.nameCountry}
                  </span>
                </h3>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="content_maps">
        <div className="box_maps">
          {myCountries.map(itemCountry => (
            <img
              key={itemCountry.id}
              className={`maps ${itemCountry.isActive ? 'active_maps' : ''}`}
              src={itemCountry.urlMapWorld}
              alt={itemCountry.nameCountry}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

// className={`span_item_country ${countryMap.isActive === true ? 'active_ttl' : ''} `}



