import React from 'react';
import { useTranslation } from "react-i18next"
import { Customers } from '../components/common/Customers'
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

export const Nosotros = () => {
  const [t] = useTranslation("global");

  return (
    <div>
      <section className="wrapper_about" id="home">
        <div className="container">
          <div className="row box_about_info align-items-center">
            <div className="col-md-6">
              <h6 className="ttls_small">{t("about.ttl_about_small")}</h6>
              <div className="about_info">
                <h1 className="ttls">Somos</h1>
                <h1 className="ttls">Red Companies</h1>
              </div>
              <p className="mt-5">
                <NavLink className="btns btn_compress" tag={Link} to="/contacto">
                  <span>
                    {t("about.link_about")}
                  </span>
                </NavLink>
              </p>
            </div>
            <div className="col-md-6 about_details">
              <h1>{t("about.about_info")}</h1>
              <p className="paragraphs in_none">{t("about.about_descrip")}</p>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper_values_descript">
        <div className="box_bgs_values">
          <div className="bgs bg_left"></div>
          <div className="bgs bg_right"></div>
        </div>
        <div className="container">
          <div className="row box_values_info">
            <div className="col-md-6 box_mision">
              <h4 className="ttls display-4">{t("about.mission")}</h4>
              <p className="paragraphs ">{t("about.mission_info")}</p>
              <h4 className="ttls display-4 about_info">{t("about.vision")}</h4>
              <p className="paragraphs ">{t("about.vision_info")}</p>
            </div>
            <div className="col-md-6 box_values">
              <h4 className="ttls display-4">{t("about.values")}</h4>
              <div>
                <p className="paragraphs item_value">
                  <strong className="fw7"> &#8226; {t("about.values_one")}: </strong>
                  <span>
                    {t("about.values_one_info")}
                  </span>
                </p>
                <p className="paragraphs item_value">
                  <strong className="fw7"> &#8226; {t("about.values_two")}: </strong>
                  <span>
                    {t("about.values_two_info")}
                  </span>
                </p>
                <p className="paragraphs item_value">
                  <strong className="fw7"> &#8226; {t("about.values_three")}: </strong>
                  <span>
                    {t("about.values_three_info")}
                  </span>
                </p>
                <p className="paragraphs item_value">
                  <strong className="fw7"> &#8226; {t("about.values_four")}: </strong>
                  <span>
                    {t("about.values_four_info")}
                  </span>
                </p>
                <p className="paragraphs item_value">
                  <strong className="fw7"> &#8226; {t("about.values_five")}: </strong>
                  <span>
                    {t("about.values_five_info")}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Customers />
    </div>
  );
}
