import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { App } from "./App";
import { unregister } from "./registerServiceWorker";
import ReactGA from "react-ga";
import { createBrowserHistory } from "history";

import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

import global_es from "./translations/global_es.json";
import global_en from "./translations/global_en.json";

i18next.init({
  interpolation: { escapeValue: false },
  resources: {
    es: {
      global: global_es,
    },
    en: {
      global: global_en,
    },
  },
  lng: "es",
});

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

//Google Analytics()
const history = createBrowserHistory();
ReactGA.initialize(process.env.REACT_APP_TRACKING_ID_GA);
ReactGA.pageview(history.location.pathname);
ReactGA.plugin.require("displayfeatures");
history.listen((location) => ReactGA.pageview(location.pathname));

ReactDOM.render(
  <Router basename={baseUrl} history={history}>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </Router>,
  rootElement
);

unregister();
