import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import $ from "jquery";
import swal from "sweetalert";

export const Footer = () => {
  const [t] = useTranslation("global");

  const handleSubmit = (event) => {
    event.preventDefault();
    let email = event.target.email.value;

    fetch("/api/Home/Newslatter?email=" + email, {
      method: "POST",
    })
      .then(function (response) {
        if (response.ok) {
          return response.json();
        }
      })
      .then(function (data) {
        if (data.status) {
          $("#frmNewlatter")[0].reset();
          swal({ text: data.message, icon: "success" });
        } else {
          swal({ text: data.message, icon: "error" });
        }
      })
      .catch(function (error) {
        swal({ text: "Error", icon: "error" });
      });
  };

  const anioActual = () => {
    var fecha = new Date().getFullYear();
    return fecha;
  };

  return (
    <div>
      <section className="wrapper_newsletter">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="item_info">
                <h6>{t("newsletter.label_newsletter")} </h6>
                <h1 className="ttls">
                  {t("newsletter.title_newsletter")}{" "}
                  <span>{t("newsletter.title_newsletter_two")}</span>{" "}
                </h1>
                <p className="fw6">{t("newsletter.small_newsletter")}</p>
              </div>
            </div>
            <div className="col-md-6">
              <form
                onSubmit={handleSubmit}
                className="box_form"
                id="frmNewlatter"
              >
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    aria-describedby="emailHelp"
                    placeholder={t("newsletter.placeholder")}
                    required
                  />
                  <button type="submit" className="btn_send">
                    <i className="fas fa-heart"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <footer className="wrapper_footer">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-md-3 bx_logo">
              <p>
                <img src="assets/imgs/logo-redco_clrs.svg" alt="Logo" />
              </p>
              <p>
                <a
                  href="https://www.facebook.com/redvoucher/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-facebook-square"></i>
                </a>
                <a
                  href="https://www.youtube.com/channel/UCvX18UUFBbrGrokwqrGBlKw"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-youtube"></i>
                </a>
              </p>
            </div>
            <div className="col-sm-6 col-md-3">
              <h6>{t("footer.title_office")}</h6>
              <p>
                {t("footer.address_office_one")}
                <br />
                {t("footer.address_office_two")}
                <br />
                {t("footer.address_office_three")}
              </p>
            </div>
            <div className="col-sm-6 col-md-3">
              <h6>{t("footer.phone_office")}</h6>
              <p>
                <a href="mailto:contacto@somosredcompanies.com">
                  <b>Email:</b> contacto@somosredcompanies.com
                </a>
              </p>
            </div>
            <div className="col-sm-6 col-md-3 bx_empresa">
              <h6>{t("footer.legal_office")}</h6>

              <p className="m-0">
                <a target='_blank' href="https://www.redvoucheronline.com.mx/terms-and-conditions">{t("footer.terms_office")}</a>
                {/* <NavLink tag={Link} to="/terminosycondiciones">{t("footer.terms_office")}</NavLink> */}
              </p>
              <p className="m-0">
                <a target='_blank' href="https://www.redvoucheronline.com.mx/notice-privacy">{t("footer.privacy_office")}</a>
                {/* <NavLink tag={Link} to="/avisosyprivacidad">{t("footer.privacy_office")}</NavLink> */}
              </p>
            </div>
          </div>
        </div>
        <div className=" foot text-center">
          <span>
            {anioActual()} Red Companies. {t("header.menu_rights")}{" "}
          </span>
        </div>
      </footer>
    </div>
  );
};
