import React, { useState, useEffect } from "react";
import { NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const Header = () => {
  const [t, i18n] = useTranslation("global");

  const [scrollTop, setScrollTop] = useState(0);
  const [openMenu, setOpenMenu] = useState("");
  const [activeLang, setActiveLang] = useState(true);

  useEffect(() => {
    const updateScroll = () => {
      const scrollY = window.scrollY;
      setScrollTop(scrollY);
    };
    updateScroll();
    window.addEventListener("scroll", updateScroll);
  });

  const funOpenMenu = () => {
    setOpenMenu(openMenu === "" ? "m_open" : "");
  };

  const funActiveLang = (lang) => {
    if (lang === "es") {
      i18n.changeLanguage("es");
      setActiveLang(true);
    } else {
      i18n.changeLanguage("en");
      setActiveLang(false);
    }
  };

  const anioActual = () => {
    var fecha = (new Date()).getFullYear();
    return fecha
  }

  return (
    <header
      className={`wrapper_header ${scrollTop > 0 ? "header_change" : ""} `}
    >
      <div
        className={`container ${scrollTop > 0 && openMenu ? "change" : ""} `}
      >
        <div className="row align-items-center justify-content-between">
          <span className="box_logo">
            <NavLink tag={Link} to="/">
              <img
                className="logo_desk"
                src="assets/imgs/logo-redco.svg"
                alt="Red Companies"
              />
              <img
                className="logo_movil"
                src="assets/imgs/logo-redco_clrs.svg"
                alt="Red Companies"
              />
            </NavLink>
          </span>
          <span className="box_options">
            <span className="box_tag_lang">
              <span
                className={`tag_lang ${activeLang ? "active" : ""}`}
                onClick={() => funActiveLang("es")}
              >
                ES
              </span>
              <span className="tag_line">/</span>
              <span
                className={`tag_lang ${!activeLang ? "active" : ""}`}
                onClick={() => funActiveLang("en")}
              >
                EN
              </span>
            </span>
            {/* <span className="follow">SÍGUENOS EN</span>
            <a className="follow_a" href="https://www.facebook.com/redvoucher/" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-square"></i></a>
            <a className="follow_a" href="https://www.youtube.com/channel/UCvX18UUFBbrGrokwqrGBlKw" target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube"></i></a> */}
            <Link to="/catalogo" className="link_catalogos">
              <span className="span_small">{t("header.label_catalogo")}</span>
              <span className="span_normal">{t("header.label_navidad")}</span>
            </Link>
            <div className={`btn_menu trans ${openMenu}`} onClick={funOpenMenu}>
              <span>
                <span className="sp_menu">MENU</span>
                <i className={`fas ${openMenu ? "fa-times" : "fa-bars"}`}></i>
              </span>
            </div>
          </span>
        </div>
      </div>
      <div className={`menu_options ${openMenu ? "is_show" : ""}`}>
        <div className="menu_box_options">
          <div className="mb-4">
            <span className="box_tag_lang mobile">
              <span
                className={`tag_lang ${activeLang ? "active" : ""}`}
                onClick={() => funActiveLang("es")}
              >
                ES
              </span>
              <span className="tag_line">/</span>
              <span
                className={`tag_lang ${!activeLang ? "active" : ""}`}
                onClick={() => funActiveLang("en")}
              >
                EN
              </span>
            </span>
          </div>
          <div className="item_options">
            <NavLink className="a_item" tag={Link} onClick={funOpenMenu} to="/">
              <span>{t("header.menu_home")}</span>
              <p>{t("header.menu_home_label")}</p>
            </NavLink>
          </div>
          <div className="item_options">
            <NavLink
              className="a_item"
              tag={Link}
              onClick={funOpenMenu}
              to="/nosotros"
            >
              <span>{t("header.menu_about")}</span>
              <p>{t("header.menu_about_label")}</p>
            </NavLink>
          </div>
          <div className="item_options">
            <NavLink
              className="a_item"
              tag={Link}
              onClick={funOpenMenu}
              to="/productos"
            >
              <span>{t("header.menu_products")}</span>
              <p>{t("header.menu_products_label")}</p>
            </NavLink>
          </div>
          <div className="item_options">
            <NavLink
              className="a_item"
              tag={Link}
              onClick={funOpenMenu}
              to="/contacto"
            >
              <span>{t("header.menu_contact")}</span>
              <p>{t("header.menu_contact_label")}</p>
            </NavLink>
          </div>
        </div>
        <p className="foot_menu">
          ©{anioActual()} Red Companies. {t("header.menu_rights")}
        </p>
      </div>
    </header>
  );
};
