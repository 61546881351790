import React from 'react';
import { useTranslation } from "react-i18next"

export const Customers = () => {
  const [t] = useTranslation("global");

  return (
    <section className="wrapper_customers">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-7 box_customer">
            <h6 className="ttls_small mb-5">{t("customers.small_customer")}</h6>
            <h1 className="ttls ttls_sections mt-5"> {t("customers.title_customer_one")} <span className="bg_span blue">{t("customers.title_customer_two")}</span></h1>
            <p className="paragraphs mt-4 pb-4">{t("customers.customer_information")}</p>
            <div className="row box_logos_custumers align-items-center justify-content-center text-center">
              <div className="col-sm-4">
                <img className="sm" src="assets/imgs/customers/logo-kidzania.png" alt="Somos Red Companies, marketing, diseño y desarrollo web, tecnología, Red Voucher, Red Voucher online, Red Access, Red Bear, Red Pay" />
              </div>
              <div className="col-sm-4">
                <img className="lg" src="assets/imgs/customers/logo-samsclub.png" alt="Somos Red Companies, marketing, diseño y desarrollo web, tecnología, Red Voucher, Red Voucher online, Red Access, Red Bear, Red Pay" />
              </div>
              <div className="col-sm-4">
                <img className="md" src="assets/imgs/customers/logo-costco.png" alt="Somos Red Companies, marketing, diseño y desarrollo web, tecnología, Red Voucher, Red Voucher online, Red Access, Red Bear, Red Pay" />
              </div>
              <div className="col-sm-4">
                <img className="lg" src="assets/imgs/customers/logo-vips.png" alt="Somos Red Companies, marketing, diseño y desarrollo web, tecnología, Red Voucher, Red Voucher online, Red Access, Red Bear, Red Pay" />
              </div>
              <div className="col-sm-4">
                <img className="sm" src="assets/imgs/customers/logo-liverpool.png" alt="Somos Red Companies, marketing, diseño y desarrollo web, tecnología, Red Voucher, Red Voucher online, Red Access, Red Bear, Red Pay" />
              </div>
              <div className="col-sm-4">
                <img className="sm" src="assets/imgs/customers/logo-cinepolis.png" alt="Somos Red Companies, marketing, diseño y desarrollo web, tecnología, Red Voucher, Red Voucher online, Red Access, Red Bear, Red Pay" />
              </div>
              <div className="col-sm-4">
                <img className="sm" src="assets/imgs/customers/logo-clubpremier.png" alt="Somos Red Companies, marketing, diseño y desarrollo web, tecnología, Red Voucher, Red Voucher online, Red Access, Red Bear, Red Pay" />
              </div>
              <div className="col-sm-4">
                <img className="lg" src="assets/imgs/customers/logo-cc.png" alt="Somos Red Companies, marketing, diseño y desarrollo web, tecnología, Red Voucher, Red Voucher online, Red Access, Red Bear, Red Pay" />
              </div>
              <div className="col-sm-4">
                <img className="md" src="assets/imgs/customers/logo-oxxo.png" alt="Somos Red Companies, marketing, diseño y desarrollo web, tecnología, Red Voucher, Red Voucher online, Red Access, Red Bear, Red Pay" />
              </div>
              <div className="col-sm-4 mt-3">
                <img className="md" src="assets/imgs/customers/logo_movistar.png" alt="Somos Red Companies, marketing, diseño y desarrollo web, tecnología, Red Voucher, Red Voucher online, Red Access, Red Bear, Red Pay" />
              </div>
            </div>
          </div>
          <div className="bg_customer"></div>
        </div>
      </div>
    </section>
  );
}
