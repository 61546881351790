import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import $ from "jquery";
import swal from "sweetalert";

export const CatalogoPromos = () => {
  const [t] = useTranslation("global");

  const download = () => {
    setTimeout(() => {
      window.location.href = "/gratitude";
    }, 500);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    fetch("/api/Home/ContactInformation", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Name: event.target.name.value,
        Email: event.target.email.value,
        PhoneNumber: event.target.phoneNumber.value,
        Comments: event.target.comments.value,
        AppName: "SomosRedCompanies",
        Reference: "Catalogo Red Companies",
      }),
    })
      .then(function (response) {
        if (response.ok) {
          return response.json();
        }
      })
      .then(function (data) {
        if (data.status) {
          $("#frmContact")[0].reset();
          swal({ text: data.message, icon: "success" });
        } else {
          swal({ text: data.message, icon: "error" });
        }
      })
      .catch(function (error) {
        swal({ text: "Error", icon: "error" });
      });
  };

  return (
    <section className="wrapper_catalogos">
      <div className="drawer_home">
        <img
          className="img_cat"
          src="assets/imgs/catalog/bg_catalogo.jpg"
          alt="Somos Red Companies, catalogo, productos, red companies, promociones, regalos"
        />
        <img
          className="img_cat_mobile "
          src="assets/imgs/catalog/bg_catalogo-mobile.jpg"
          alt="Somos Red Companies, catalogo, productos, red companies, promociones, regalos"
        />

        {/* <div className="box_btns">
          <div className="content_btns">
            <a
              download="Catálogo - Red Companies"
              href="assets/RedCo-Catalogo.pdf"
              onClick={download}
            >
              <img
                src="assets/imgs/catalog/btn_download.png"
                alt="Descarga catalogo Red Companies"
              />
            </a>
          </div>
        </div> */}

        <div className="box_btns">
          <div className="content_btns">
            <a
              download="Catálogo - Red Companies"
              href="assets/RedCo-Catalogo.pdf"
              onClick={download}
            >
              <img
                src="assets/imgs/catalog/btn_redco.png"
                alt="Descarga catalogo Red Companies"
              />
            </a>
            {/* <img
              className="logo_wht"
              src="assets/imgs/catalog/logo-redco.png"
              alt="Somos Red Companies"
            />
            <Link to="/">
              <img
                src="assets/imgs/catalog/btn-right.png"
                alt="Conoce Red Companies"
              />
            </Link> */}
          </div>
        </div>
      </div>
      <div className="drawer_form">
        <div className="container_box">
          <div className="paragraphs text-center">
            <p>{t("others.download_info_form")}</p>
            <p>{t("others.download_info_form_pt2")}</p>
            <p>
              <span className="fw-700">
                {t("others.download_info_form_two")}
              </span>
            </p>
          </div>
          <form
            className="box_form_cat"
            onSubmit={handleSubmit}
            id="frmContact"
          >
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group mb-4">
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder={t("contact.form_name_user")}
                    required
                  />
                </div>
                <div className="form-group mb-4">
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    placeholder={t("contact.form_email")}
                    required
                  />
                </div>
                <div className="form-group mb-4">
                  <input
                    type="text"
                    className="form-control"
                    id="phoneNumber"
                    name="phoneNumber"
                    placeholder={t("contact.form_phone")}
                    required
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group mb-4">
                  <textarea
                    className="form-control"
                    id="comments"
                    name="comments"
                    placeholder={t("contact.form_comments")}
                    rows="6"
                    required
                  ></textarea>
                </div>
              </div>
            </div>
            <p className="mt-2 text-right">
              <button type="submit" className=" trans btn_cont_cat ">
                <i className="fas fa-paper-plane"></i> &nbsp;{" "}
                {t("contact.form_send")}
              </button>
            </p>
          </form>
        </div>
      </div>
    </section>
  );
};
