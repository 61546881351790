import React from "react";
import { useTranslation } from "react-i18next";

export const AvisoPrivacidad = () => {
  const [t] = useTranslation("global");

  return (
    <section className="wrapper_legals">
      <div className="box_tittle">
        <div className="container">
          <h1 className="ttls mb-2">{t("others.privacy_notice")}</h1>
          <h6 className="ttls_small">RED VOUCHER</h6>
        </div>
      </div>
      <div className="content_legals">
        <div className="container">
          <p>{t("notice_privacy.privacy_p1")}</p>
          <h6 className="fnt_text is_fnt_medium">
            {t("notice_privacy.privacy_ttl_i")}
          </h6>
          <p>{t("notice_privacy.privacy_p2")}</p>
          <p>{t("notice_privacy.privacy_p3")}</p>
          <p>{t("notice_privacy.privacy_p4")}</p>
          <p>{t("notice_privacy.privacy_p5")}</p>
          <p>{t("notice_privacy.privacy_p6")}</p>
          <h6 className="fnt_text is_fnt_medium">
            {t("notice_privacy.privacy_ttl_ii")}
          </h6>
          <p>{t("notice_privacy.privacy_p7")}</p>
          <p>{t("notice_privacy.privacy_p8")}</p>
          <h6 className="fnt_text is_fnt_medium">
            {t("notice_privacy.privacy_ttl_iii")}
          </h6>
          <p>{t("notice_privacy.privacy_p9")}</p>
          <h6 className="fnt_text is_fnt_medium">
            {t("notice_privacy.privacy_ttl_iv")}
          </h6>
          <p>{t("notice_privacy.privacy_p10")}</p>
          <p>{t("notice_privacy.privacy_p11")}</p>
          <p>{t("notice_privacy.privacy_p12")}</p>
          <p>{t("notice_privacy.privacy_p13")}</p>
          <h6 className="fnt_text is_fnt_medium">
            {t("notice_privacy.privacy_ttl_v")}
          </h6>
          <p>{t("notice_privacy.privacy_p14")}</p>
          <p>{t("notice_privacy.privacy_p15")}</p>
          <p>{t("notice_privacy.privacy_p16")}</p>
          <h6 className="fnt_text is_fnt_medium">
            {t("notice_privacy.privacy_ttl_vi")}
          </h6>
          <p>{t("notice_privacy.privacy_p17")}</p>
          <p>{t("notice_privacy.privacy_p18")}</p>
          <p>{t("notice_privacy.privacy_p19")}</p>
          <p>{t("notice_privacy.privacy_p20")}</p>
          <h6 className="fnt_text is_fnt_medium">
            {t("notice_privacy.privacy_ttl_vii")}
          </h6>
          <p>{t("notice_privacy.privacy_p21")}</p>
          <p>{t("notice_privacy.privacy_p22")}</p>
          <p>{t("notice_privacy.privacy_p23")}</p>
          <p>{t("notice_privacy.privacy_p24")}</p>
          <p>{t("notice_privacy.privacy_p25")}</p>
          <p>{t("notice_privacy.privacy_p26")}</p>
          <p>{t("notice_privacy.privacy_p27")}</p>
          <p>{t("notice_privacy.privacy_p28")}</p>
          <p>{t("notice_privacy.privacy_p29")}</p>
          <h6 className="fnt_text is_fnt_medium">
            {t("notice_privacy.privacy_ttl_viii")}
          </h6>
          <p>{t("notice_privacy.privacy_p30")}</p>
          <p>{t("notice_privacy.privacy_p31")}</p>
          <p>{t("notice_privacy.privacy_p32")}</p>
          <p>{t("notice_privacy.privacy_p33")}</p>
          <p>{t("notice_privacy.privacy_p34")}</p>
          <h6 className="fnt_text is_fnt_medium">
            {t("notice_privacy.privacy_ttl_ix")}
          </h6>
          <p>{t("notice_privacy.privacy_p35")}</p>
          <p>{t("notice_privacy.privacy_p36")}</p>
          <p>{t("notice_privacy.privacy_p37")}</p>
          <p>{t("notice_privacy.privacy_p38")}</p>
          <p>{t("notice_privacy.privacy_p39")}</p>
          <p>{t("notice_privacy.privacy_p40")}</p>
          <p>{t("notice_privacy.privacy_p41")}</p>
          <h6 className="fnt_text is_fnt_medium">
            {t("notice_privacy.privacy_ttl_x")}
          </h6>
          <p>{t("notice_privacy.privacy_p42")}</p>
          <p>{t("notice_privacy.privacy_p43")}</p>
          <p>{t("notice_privacy.privacy_p44")}</p>
          <h6 className="fnt_text is_fnt_medium">{t("notice_privacy.privacy_p45")}</h6>
        </div>
      </div>
    </section>
  );
};
