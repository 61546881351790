import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next"
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Header } from './common/Header';
import { Footer } from './common/Footer';
import '../theme/css/main.css'

export const Layout = (props) => {

  useEffect(() => {
    const scrollToTop = () => {
      // window.scrollTo({
      //   top: 0,
      //   behavior: "smooth"
      // });
      window.scrollTo(0, 0);
    }
    scrollToTop()
  })

  return (
    <section>
      <Header />
      {props.children}
      <Footer />
    </section>
  )
}

export const LayoutLess = (props) => {
  const [t] = useTranslation("global");

  return (
    <section>
      <header className="wrapper_header header_less">
        <div className="container">
          <div className="row justify-content-between">
            <span className="box_logo p-3">
              <NavLink tag={Link} to="/">
                <img className="logo_desk" src="assets/imgs/logo-redco.svg" alt="Red Companies" />
                <img className="logo_movil" src="assets/imgs/logo-redco_clrs.svg" alt="Red Companies" />
              </NavLink>
            </span>
            <span className="box_options">
              <Link className="btn_go_back " to="/"><i className="fas fa-angle-left"></i> {t("others.to_return")}</Link>
            </span>
          </div>
        </div>
      </header>
      {props.children}
    </section>
  )
}