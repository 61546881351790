import React from 'react';
import { useTranslation } from "react-i18next"
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import $ from "jquery";
import swal from "sweetalert";

export const Contacto = () => {
  const [t] = useTranslation("global");

  const handleSubmit = (event) => {
    event.preventDefault();
    if (event.target.IAgree.checked) {
      fetch('/api/Home/ContactInformation', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          Name: event.target.name.value,
          Email: event.target.email.value,
          Comments: event.target.comments.value,
          AppName: "SomosRedCompanies",
          Reference: event.target.reference.value
        })
      }).then(function (response) {
        if (response.ok) {
          return response.json()
        }
      }).then(function (data) {
        if (data.status) {
          $("#frmContact")[0].reset();
          swal({ text: data.message, icon: "success" });
        } else {
          swal({ text: data.message, icon: "error" });
        }
      }).catch(function (error) {
        swal({ text: "Error", icon: "error" })
      });
    } else {
      swal({ text: "Debes aceptar las políticas de privacidad", icon: "warning" })
    }
  }
  return (
    <div>
      <section className="wrapper_contact" id="home">
        <div className="flex_container vh">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-sm-10 contact_info">
                <h6 className="ttls_small">{t("contact.ttl_small_contact")}</h6>
                <div className="">
                  <h1 className="ttls">{t("contact.ttl_contact_one")} <span>{t("contact.ttl_contact_two")}</span></h1>
                  <p className="paragraphs">{t("contact.info_contact")}</p>
                  <p className="mt-5">
                    <NavLink className="btns btn_compress" tag={Link} to="/nosotros">
                      <span>
                        {t("contact.link_contact")} </span>
                    </NavLink>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper_forms">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="box_forms_info">
                <div>
                  <h1 className="ttls">{t("contact.msj_contact_one")} <span>{t("contact.msj_contact_two")}</span></h1>
                  <p className="paragraphs">
                    {t("contact.msj_contact_info")}
                    </p>
                </div>
                <div className="text-right sec_info_media">
                  <div className="box_info_media">
                    <div>
                      <h5 className="fw3 text-black-50">{t("contact.follow_us")}</h5>
                      <p className="social_media">
                        <a href="https://www.facebook.com/redvoucher/" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-square"></i></a>
                        <a href="https://www.youtube.com/channel/UCvX18UUFBbrGrokwqrGBlKw" target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube"></i></a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="box_forms_group">
                <h4 className=" ttl_form text-center"><i className="fas fa-map-marker-alt"></i> {t("contact.form_contact")}</h4>
                <div className="box_infocont">
                  <h6>{t("contact.office_contact")}</h6>
                  <p className="mb-3">
                    {t("contact.addres_contact")}</p>
                  <p><a href="mailto:contacto@somosredcompanies.com"><b>Email:</b> contacto@somosredcompanies.com</a></p>
                </div>
                <hr />
                <h4 className=" ttl_form text-center"><i className="fas fa-paper-plane"></i> {t("contact.form_contact_send")}</h4>
                <form onSubmit={handleSubmit} id="frmContact">
                  <div className="form-group">
                    <input type="text" className="form-control" id="name" name="name" placeholder={t("contact.form_name_user")} required />
                  </div>
                  <div className="form-group">
                    <input type="email" className="form-control" id="email" name="email" placeholder={t("contact.form_email")} required />
                  </div>
                  <div class="form-group">
                    <select class="form-control" name="reference" id="reference" required>
                      <option value="" disabled selected="selected">{t("contact.form_hear")}</option>
                      <option value="Facebook">Facebook</option>
                      <option value="Google">Google</option>
                      <option value="LinkedIn">LinkedIn</option>
                      <option value="Recomendación">Recomendación</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <textarea className="form-control" id="comments" name="comments" placeholder={t("contact.form_comments")} rows="3" required></textarea>
                  </div>
                  <div className="form-group">
                    <div className="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="IAgree" name="IAgree" />
                      <label className="custom-control-label" for="IAgree">{t("contact.form_have_read_one")} <a href="/avisosyprivacidad" target="_blank" style={{ color: "red" }} >{t("contact.form_have_read_two")}</a></label>
                    </div>
                  </div>
                  <p className="mt-2 text-center">
                    <button type="submit" className="btn_send btns "><i className="fas fa-paper-plane"></i> {t("contact.form_send")}</button>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="box_maps">
          <div className="i_maps">
            <iframe title='Mapa de ubicación Red Companies' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3763.4537731195214!2d-99.17965538526511!3d19.392790286906912!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1ff70f5203bd1%3A0x1629df4c87ad9bb8!2sTexas%2039%2C%20N%C3%A1poles%2C%20Benito%20Ju%C3%A1rez%2C%2003810%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX!5e0!3m2!1ses-419!2smx!4v1582213171260!5m2!1ses-419!2smx" frameborder="0" allowfullscreen=""></iframe>
          </div>
        </div>
      </section>
    </div>
  );
}



